// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";
import {useStaticQuery, graphql} from "gatsby";

import {
  BREAKPOINTS,
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
} from "../../../styles/sizes";
import {WHITE, TEXT_GRAY, TEXT_BLACK} from "../../../styles/colors";

type ContentfulFooterWrapperProps = {|
  +mobileMenuIsOpen?: boolean,
|};

function ContentfulFooterWrapper(
  props: ContentfulFooterWrapperProps
): React.Node {
  const intl = useIntl();
  const locale = intl.locale || intl.defaultLocale;
  const localeSlug = locale === "de" ? "/de" : "";

  const data = useStaticQuery(
    graphql`
      query ContentfulFooterQuery {
        allContentfulFooter(
          filter: {contentful_id: {eq: "30vUMqK04nn2Mrn1nYec2U"}}
        ) {
          nodes {
            node_locale
            ...ContentfulFooterFragment
          }
        }
      }
    `
  );

  const localFooterData = data.allContentfulFooter.nodes.find(
    el => el.node_locale === locale
  );
  const totalCtas = localFooterData?.ctas?.length - 1 || 0;

  const inertProps = {};
  if (props.mobileMenuIsOpen) {
    inertProps.inert = 1;
  }

  return (
    <footer className={css(styles.section)} {...inertProps}>
      <div className={css(styles.copyright)}>
        {localFooterData?.copyrightCopy}
      </div>
      <div className={css(styles.links)}>
        {localFooterData?.ctas?.map((cta, index) => (
          <span key={cta.label}>
            <a className={css(styles.link)} href={cta.link}>
              {cta.label}
            </a>

            <span className={css(styles.linkSeparator)}>|</span>
          </span>
        ))}
        <a
          onClick={e => {
            e.preventDefault();
            window.semaphore.push(["showPreferences"]);
          }}
          className={css(styles.link)}
          href="#"
        >
          Privacy Settings
        </a>
      </div>
    </footer>
  );
}

const styles = StyleSheet.create({
  section: {
    display: "flex",
    background: WHITE,
    color: TEXT_GRAY,
    fontSize: "14px",
    padding: `25px ${SIDE_MARGIN_MOBILE}`,
    flexDirection: "column-reverse",
    justifyContent: "left",
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: `15px ${SIDE_MARGIN}`,
      justifyContent: "space-between",
      flexDirection: "row",
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: `15px ${SIDE_MARGIN_WIDE}`,
    },
  },
  links: {
    display: "flex",
    paddingBottom: "15px",
    [`${BREAKPOINTS.DESKTOP}`]: {
      display: "block",
      paddingBottom: "0px",
    },
  },
  link: {
    color: TEXT_GRAY,
    textDecoration: "none",
    ":hover": {
      color: TEXT_BLACK,
    },
    margin: "0px",
  },
  linkSeparator: {
    padding: "0px 5px",
  },
});

export default ContentfulFooterWrapper;
